import * as React from 'react'
import * as styles from '../stylesheets/about.module.css'
import { StaticImage } from "gatsby-plugin-image"


const Biography = () => {
    return (
        <section className={styles.aboutLanding}>
            <StaticImage
                src='../images/andrea_office.jpg'
                alt="andrea-office"
                className={styles.profileImage}
            />
            <div className={styles.aboutBio}>
                <p style={{textAlign: 'right'}}>
                    Andrea was born and raised in Southern California, a first-generation Chicana with a passion for assisting all
                    communities. Andrea is a fair and experienced professional in her field, always standing on the side of justice.
                    She is currently a Legal Assistant for one of Amazon's Legal Team.
                </p>
            </div>
            <div className={styles.aboutBio}>
                <p style={{ textAlign: 'left' }}> 
                    Andrea has earned a Bachelor of Arts degree from California State University, Long Beach with a focus on Women's Studies
                    and political organizations on campus. She went on to participate in the Panetta Congressional Internship Program.
                    With an insight into the inner workings of Washington D.C. Congressional Law and experience assisting local 
                    attorneys focused on immigration rights, Andrea is ready to help her clients and her community. 
                </p>
            </div>
            <StaticImage
                src='../images/kamalaH.jpg'
                alt="andrea-office"
                className={styles.kamHImage}
            />
            <div className={styles.aboutBio}>
                <p style={{ textAlign: 'left' }}>
                    Most notably, Andrea was a Congressional Intern for former congresswoman and current U.S. Vice President, Kamala Harris. 
                    She is a resource to Los Angeles locals, and now an asset to one of Amazon's legal teams.
                </p>
            </div>
        </section>
    )
}

export default Biography;