import React from 'react'
import { graphql } from 'gatsby'
import Layout from '../components/layout'
import Seo from '../components/seo'
import Biography from '../components/biography'
import SkillList from '../components/skillList'
import Gallery from '../components/gallery'
import EmailForm from '../components/emailForm'
import * as styles from '../stylesheets/about.module.css'
// import { StaticImage } from 'gatsby-plugin-image'

export default function About({ data }) {
    const style = { flexDirection: "column", justifyContent: "center" };
    return (
        <Layout pageTitle={About}>
            <Seo title='About' />
            <section className={styles.landing}>
                <h1>About Andrea</h1>
            </section>
            <Biography />
            <SkillList style={style} />
            <h2 style={{textAlign: 'center', fontSize: '2.5rem' }}>Prior Work Experience</h2>
            <Gallery />
            <h2 style={{ textAlign: 'center', fontSize: '2.5rem' }}>Get in Touch</h2>
            <EmailForm />
        </Layout>
    )
}

export const query = graphql`
    query {
        site {
            siteMetadata {
                title
                description
            }
        }
    }
    `